#block {
  .top {
    img {
      width: 100%;
    }
  }

  .span /deep/ {
    font-size: 12px;
    line-height: 1.2;
    color: $text-secondary;
    padding: 20px 20px 0;
    word-break: break-word;

    strong {
      color: $danger;
      display: inline-block;
      padding-bottom: 10px;
    }
  }

  .middle {
    padding: 0 20px 20px;
    font-size: 12px;
    color: $danger;
  }

  .middle {
    width: 100%;
    min-height: 20px;
    margin-top: 10px;
    word-break: break-word;
  }

  .upload {
    // max-width: 334px;
    margin: 0 auto 20px;
    .upload_tip {
      color: $danger;
      padding-bottom: 10px;
    }

    /deep/ .el-upload {
      border: 1px solid #dfe4eb;
      border-radius: 4px;
    }

    .finish /deep/ .el-upload {
      border: 1px solid $success;
    }

    .message {
      width: 100%;
      min-height: 136px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .message_icon {
        font-size: 40px;
        color: $success;
      }
      .el-icon-plus {
        font-size: 14px;
        font-size: #201e1e;
      }

      .text {
        color: #7a8699;
        text-transform: uppercase;
        font-size: 12px;
        line-height: normal;
      }
    }
  }
}

// @media (max-width: 767px) {
//   #block .upload {
//     max-width: 85%;
//   }
// }
