
              @import "@/assets/css/variables.scss";
            








.card {
  border-radius: 8px;
  background-color: $light-gray;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0;

  .header {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .card {
    padding: 20px;
  }
}
