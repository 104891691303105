
              @import "@/assets/css/variables.scss";
            









































































@import '@/assets/css/components/home/uploadIdOrAddress/block.scss';

.svg-icon {
  font-size: 32px;
}

.upload_btn {
  font-size: 12px;
  color: #7A8699;
  line-height: 18px;
  margin-top: 4px;
}
