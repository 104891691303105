#UploadIdOrAddress {
  /deep/ .el-dialog__body {
    // word-break: break-word;
    // padding: 45px 50px !important;

    // .header {
    //   font-size: 15px;
    //   color: $danger;
    //   text-transform: uppercase;
    //   line-height: 1.2;
    //   text-align: center;

    //   .bottom {
    //     color: $bottle-blue;
    //     margin-top: 10px;
    //     margin-bottom: 10px;
    //     text-transform: none;
    //   }
    // }
    .header {
      padding-bottom: 20px;
      color: $danger;
      text-align: center;
    }
    .body {
      .bottom {
        padding-bottom: 10px;
      }
    }

    .id-form {
      .title {
        font-weight: 200;
        color: #000a2d;
        margin-bottom: 25px;
        font-weight: bold;
      }
      > ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      }
    }

    .errMsg {
      color: $danger;
      margin: 0 auto 10px;
    }

    .btn-box {
      text-align: center;

      .btn-red {
        width: 100%;
        max-width: 334px;
      }
    }
  }
}

@media (max-width: 767px) {
  #UploadIdOrAddress /deep/ .el-dialog__body {
    // padding: 20px !important;

    .errMsg {
      max-width: 85%;
    }

    .btn-box .btn-red {
      max-width: 85%;
    }
  }
}
